import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Backoffice from '../../core/sysext/CMS/containers/Backoffice';
import MemoForm from '../../core/sysext/Website/containers/Memo/components/Form';
import MemoList from '../../core/sysext/Website/containers/Memo/components/List';

const MemosPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      {star ? <MemoForm docId={star} {...props} /> : <MemoList {...props} />}
    </Backoffice>
  );
};

export default MemosPage;
